<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <h3 class=" text-danger text-center mb-1 mt-1">
          İşveren bilgileri tamamlanmamış kayıtlar listeye gelmeyecektir. Önce işveren bilgilerini tamamlayınız.
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="9"
      >
        <vue-autosuggest
          v-model="query"
          :suggestions="filteredOptions"
          :get-suggestion-value="getSuggestionValue"
          :input-props="{id:'autosuggest__input', class:'form-control', placeholder:'Talep oluşturulacak firmayı seçiniz.'}"
          :limit="10"
          @selected="onSelected"
        >
          <div
            slot-scope="{suggestion}"
          >
            <div>
              {{ suggestion.item.firmTitle }}
            </div>
          </div>
        </vue-autosuggest>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="d-flex align-items-center"
          squared
          variant="primary"
          :disabled="selected==''"
          @click="OnAddClick(selected)"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span>Yeni Talep Ekle</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import * as apiFirms from '@/api/islemler/firminventory'
import { BCol, BRow, BButton } from 'bootstrap-vue'

export default {
  components: {
    VueAutosuggest,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      query: '',
      selected: '',
      suggestions: [{
        data: [],
      }],
    }
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data.filter(option => option.firmTitle.toLowerCase().indexOf(this.query.toLowerCase()) > -1 && option.validationErrors === ''),
        },
      ]
    },
  },
  created() {
    apiFirms.GetFirmsAll().then(res => {
      this.suggestions[0].data = res.data.list
    })
  },
  mounted() {
    apiFirms.GetFirmsAll().then(res => {
      this.suggestions[0].data = res.data.list
    })
  },
  methods: {
    onSelected(item) {
      this.selected = item.item
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.firmTitle
    },
    OnAddClick(sel) {
      this.$router.push({ name: 'yeni-talep', params: { firmInfo: sel, firmStaffRequestId: -1 } })
    },
  },
}
</script>
